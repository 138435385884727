<template>
	<div class="d-flex flex-column vesselsmappanel fill-height max-height-100">
		<div class="d-flex flex-column py-6 px-3 mb-3" ref="header">
			<div class="vessels-title">{{ $t('operations.vessels.title') }}</div>
		</div>
		<div class="pb-0 px-3">
			<v-row>
				<v-col cols="12">
					<v-text-field
						ref="searchingText"
						:placeholder="$t('operationstoolbar.search')"
						v-model="$store.state.mappanelais.searchingtext"
						@input="filterVesselsMapByText"
						prepend-inner-icon="far fa-search"
						outlined
						filled
						dense
						background-color="red"
						hide-details
					></v-text-field>
				</v-col>
			</v-row>
			<!--<div
				v-if="!allPanels"
				class="pt-2 mr-2 vessels-subtitle font-weight-medium"
				style="cursor: pointer; color: var(--primary); float: right"
				@click="allPanels = true"
			>
				<v-icon class="mr-2" small color="var(--primary)">far fa-chevron-down</v-icon>
				{{ $t('operations.vessels.all') }}
			</div>
			<div
				v-else
				class="pt-2 mr-2 vessels-subtitle font-weight-medium"
				style="cursor: pointer; color: var(--primary); float: right"
				@click="allPanels = false"
			>
				<v-icon class="mr-2" small color="var(--primary)">far fa-chevron-up</v-icon>
				{{ $t('operations.vessels.none') }}
			</div> -->
		</div>
		<div class="lists pb-0 px-3">
			<cards-panel-items
				:active="layersAisPanelActive"
				:switchoff-text="$t('operations.layers.switchoffLayers')"
				:stops="allStopsComputed"
				:all="allPanels"
				:currentmap="currentmap"
			></cards-panel-items>
		</div>
	</div>
</template>

<script>
import CardsPanelItems from './cards/CardsPanelItems.vue';

import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';

/** Mixins */
import LayersActiveMixin from '@/mixins/LayersActiveMixin';

export default {
	name: 'vesselmappanel',

	mixins: [LayersActiveMixin],

	props: {
		aisStops: {
			type: Array,
			required: true
		},
		currentmap: {
			type: String,
			required: true
		}
	},

	components: { CardsPanelItems },

	data() {
		return {
			allPanels: false,
			stopsConfig: {},
			allStops: [],
			searchingText: ''
		};
	},
	created() {
		if (this.mapAisIsLoaded) {
			const mapFitPortExtent = this.$store.getters.getMapAisFitPortExtent;
			if (mapFitPortExtent) {
				ol2map.zoomToExtent(mapFitPortExtent);
			}
		}
		this.getStops();
	},
	computed: {
		mapAisIsLoaded() {
			return this.$store.getters.getMapAisIsLoaded;
		},
		allStopsComputed() {
			const searchingText = this.$store.state.mappanelais.searchingtext.toLowerCase();
			// Función de comparación personalizada
			const customCompare = (a, b) => {
				if (!a.vesselname && !b.vesselname) {
					return 0; // Ambos son undefined, son iguales
				} else if (!a.vesselname) {
					return 1; // a es undefined, debe venir después que b
				} else if (!b.vesselname) {
					return -1; // b es undefined, debe venir después que a
				} else if (a.vesselname === '' && b.vesselname === '') {
					return 0; // Ambos son cadenas vacías, son iguales
				} else if (a.vesselname === '') {
					return 1; // a es una cadena vacía, debe venir después que b
				} else if (b.vesselname === '') {
					return -1; // b es una cadena vacía, debe venir después que a
				} else {
					// Ordenar alfabéticamente en función de vesselname
					return a.vesselname.localeCompare(b.vesselname);
				}
			};
			if (this.layerAISActive) {
				// Filtramos AIS
				var self = this;
				const aisStops = this.aisStops.sort(customCompare).filter((x) => {
					var isVisible = (x.vesselname?.toLowerCase() || '').includes(searchingText) || searchingText === '';

					if (x.otherVessel) {
						isVisible = self.layerAISOtherFilterActive && isVisible;
					}
					if (x.isService) {
						isVisible = self.layerAISServiceFilterActive && isVisible;
					} else {
						if (x.portCallNumber) {
							isVisible = self.layerAISVisitFilterActive && isVisible;
						}
					}
					return isVisible;
				});
				// Filtramos bbdd, ya estaban ordenados alfabeticamente, al pedirlo asi de bbdd
				const stops = this.allStops.filter((x) => (x.vesselname?.toLowerCase() || '').includes(searchingText) || searchingText === '');

				return stops.concat(aisStops);
			} else {
				return this.allStops
					.sort(customCompare)
					.filter((x) => (x.vesselname?.toLowerCase() || '').includes(searchingText) || searchingText === '');
			}
		},

		stopsAccepted() {
			return this.$store.getters.getStopsFeaturesAccepted;
		},
		stopsPlanned() {
			return this.$store.getters.getStopsFeaturesPlanned;
		},
		stopsAuthorized() {
			return this.$store.getters.getStopsFeaturesAuthorized;
		},
		stopsConfirmed() {
			return this.$store.getters.getStopsFeaturesConfirmed;
		},
		stopsInitiated() {
			return this.$store.getters.getStopsFeaturesInitiated;
		}
	},
	methods: {
		filterVesselsMapByText() {
			ol2map.setVisibleFeatureByVesselNameText(this.$store.state.mappanelais.searchingtext);
			ol2map.setVisibleFeatureByFilterAIS(
				this.$store.state.mappanelais.searchingtext,
				this.layerAISVisitFilterActive,
				this.layerAISServiceFilterActive,
				this.layerAISOtherFilterActive
			);
		},

		getStops() {
			if (this.layerStopAcceptedActive) {
				this.allStops.push(...this.stopsAccepted);
			}

			if (this.layerStopPlannedActive) {
				this.allStops.push(...this.stopsPlanned);
			}

			if (this.layerStopAuthorizedActive) {
				this.allStops.push(...this.stopsAuthorized);
			}

			if (this.layerStopConfirmedActive) {
				this.allStops.push(...this.stopsConfirmed);
			}

			if (this.layerStopInitiatedActive) {
				this.allStops.push(...this.stopsInitiated);
			}
			//return this.allStops.sort((a, b) => a.id < b.id);
			return this.allStops.sort((a, b) => {
				return (a.vesselname ?? '').localeCompare(b.vesselname);
			});
		}
	}
};
</script>

<style lang="postcss" scoped>
.vesselsmappanel {
	background-color: #f7f8fc;
	& .list-icon {
		color: var(--primary);

		&--opened {
			transform: rotateZ(-90deg) !important;
		}
	}

	& .lists {
		overflow-x: hidden;
		overflow-y: auto;

		& .maintitle {
			font-weight: 700;
			font-size: 1.1rem;
			font-weight: bold;
			color: var(--primary);
		}
	}

	& .vessels-title {
		font-size: 1.3rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: var(--high);
		margin-bottom: 2px;
	}

	& .vessels-subtitle {
		font-size: 14px;
		line-height: 16.5px;
		letter-spacing: normal;
		color: #6f7480;
		margin-top: 2px !important;
	}
}
.btn {
	border-radius: 2px !important;
	min-width: 25px !important;
	min-height: 100%;
	color: gray;
	&.btnleft {
		margin-right: 4px !important;
	}

	& i {
		color: var(--brownish-grey);
	}
	&:hover {
		background-color: var(--primary) !important;
		& i {
			color: var(--negative);
		}
	}
	&.selected {
		background-color: var(--accent !important);
		& i {
			color: var(--negative);
		}
	}
	&.applied {
		background-color: var(--warning);
		& i {
			color: black;
		}
	}
}
</style>
